











import {
    Vue, Component, Prop,
} from 'vue-property-decorator'
import {State} from 'vuex-class'
import api from '~/api'
import utils from '~/utils'

@Component({
    name: 'FavoriteIcon',
    components: {
    },
})
export default class FavoriteIcon extends Vue {
    @Prop({ default: false }) actived!: boolean

    @Prop({ default: 'category' }) type!: string

    @Prop({ default: null }) id!: number

    @Prop({ default: 0 }) fastMoving!: number

    @State('token') token

    favoriteOperate() {
        if (!this.token) {
            this.$store.commit('SET_LOGIN_MODAL', true)
            return
        }
        if (this.actived) {
            let fn, params
            if (this.type === 'category') {
                fn = api.cancel_collect_category
                params = {
                    categoryId: this.id,
                    isFastMoving: this.fastMoving,
                }
            } else {
                fn = api.my_favorite_cancel
                params = {
                    skuOrgId: this.id
                }
            }
            fn(params).then(() => {
                this.actived = false
                this.$emit('update:actived', false)
            })
        } else {
            let fn, params
            if (this.type === 'category') {
                fn = api.collect_category
                params = {
                    categoryId: this.id,
                    isFastMoving: this.fastMoving,
                }
            } else {
                fn = api.my_favorite_collect
                params = {
                    skuOrgId: this.id
                }
            }
            fn(params).then(() => {
                this.$emit('update:actived', true)
                // utils.fbq('track', 'AddToWishlist', {
                //     content_name: this.productInfo.goodsName || this.productInfo.title,
                //     content_category: this.productInfo.thirdCategoryName,
                //     content_ids: this.productInfo.skuOrgId,
                //     contents: [{
                //         id: this.productInfo.skuOrgId,
                //         quantity: 1,
                //     }],
                //     currency: this.$store.state.currentCountry.currency,
                //     value: this.productInfo.standardPrice,
                // })
            })
        }
    }

}
